<template>
    <div class="jh-container">
        <div class="jh-ui-header">
            <h1>신청이력 관리</h1>
            <div class="is-right">
            </div>
        </div>
        <div class="jh-search-form">
            <table>
                <colgroup>
                    <col width="55px">
                    <col width="250px">
                    <col width="70px">
                    <col width="120px">
                    <col width="70px">
                    <col width="120px">
                    <col width="60px">
                    <col width="120px">
                    <col width="90px">
                    <col width="120px">
                    <col width="90px">
                    <col>
                </colgroup>
                <tr>
                    <th><label>조회기간</label></th>
                    <td>
                        <div class="jh-cols">                        
                            <v-menu v-model="menuStartDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="from" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuStartDate = false"
                                maxlength="10" @input="fromOnInput" @click:clear="dates[0] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                                </template>
                                <v-date-picker class="jh-calendar" v-model="dates[0]" @input="menuStartDate = false" no-title locale="ko-KR" :max="dates[1]" :day-format="mixin_getDate" @change="changeDate('start')"></v-date-picker>
                            </v-menu>
                            <span class="jh-unit">~</span>                       
                            <v-menu v-model="menuEndDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="to" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuEndDate = false"
                                maxlength="10" @input="toOnInput" @click:clear="dates[1] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                                </template>
                                <v-date-picker class="jh-calendar" v-model="dates[1]" @input="menuEndDate = false" no-title locale="ko-KR" :min="dates[0]" :day-format="mixin_getDate" @change="changeDate('end')"></v-date-picker>
                            </v-menu>
                        </div>
                    </td>
                    <th><label>고객번호</label></th>
                    <td>
                        <input type="text" class="jh-form" v-model="INQRY_CUST_NO" @keyup.enter="selectbutton">
                    </td>
                    <th><label>고객명</label></th>
                    <td>
                        <input type="text" class="jh-form" v-model="INQRY_CUST_NM" @keyup.enter="selectbutton">
                    </td>
                    <th><label>사번</label></th>
                    <td>
                        <input type="text" class="jh-form" v-model="INQRY_CSLT_ID" @keyup.enter="selectbutton">
                    </td>
                    <th><label>신청직원명</label></th>
                    <td>
                        <input type="text" class="jh-form" v-model="INQRY_CSLT_NM" @keyup.enter="selectbutton">
                    </td>
                    <td class="is-jh-20">
                        <v-checkbox class="jh-check" v-model="checkbox1" label="미처리"></v-checkbox>
                    </td>
                    <td class="has-search"><v-btn class="jh-btn is-search" @click="selectbutton">조회</v-btn></td>
                </tr>
            </table>
        </div>
        <div class="jh-form-wrap is-pt-10">
            <div class="jh-ui-header">
                <h2>신청 리스트</h2>
                <div class="is-right">
                    <v-btn class="jh-btn is-light" @click="excelDownload('divGridAplyWorkList', '캘린더/상장책자 신청 리스트(관리자)')">엑셀다운로드</v-btn>
                </div>
            </div>
            <data-tables
            ref="dataTable"
            id="divGridAplyWorkList"
            dataTableClass="has-control"
            v-model="selectedList"
            :data-table-options="dataTableOptions"
            @click:row="onClickRow"
            :paginationOptions="paginationOptions"
            ></data-tables>
        </div>

        <div class="jh-cols is-mt-10">
            <div class="jh-form-wrap is-border-blue is-pt-10 is-col-fix" style="width: 300px;">
                <div class="jh-ui-header">
                    <h2>신청 처리</h2>
                    <div class="is-right">
                    </div>
                </div>
                <table class="jh-tbl-detail">
                    <colgroup>
                        <col width="90px">
                        <col>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th><label>처리자사번</label></th>
                            <td>
                                <input type="text" class="jh-form is-trns" v-model="REGIST_PROC_MAN" readonly>
                            </td>
                        </tr>
                        <tr>
                            <th><label>처리자명</label></th>
                            <td>
                                <input type="text" class="jh-form is-trns" v-model="REGIST_PROC_NM" readonly>
                            </td>
                        </tr>
                        <tr>
                            <th><label>처리결과</label></th>
                            <td>
                                <v-select class="jh-form" v-model="REGIST_PROC_RSLT_CD" :items="AW05_COMBO" item-text="CD_NM" item-value="CD" placeholder="선택"></v-select>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="jh-btn-area is-mt-10">
                    <v-btn class="jh-btn is-md is-main" @click="updateResult" v-if="this.mixin_set_btn(this.$options.name, 'btnSave')">처리</v-btn>
                    <v-btn class="jh-btn is-md is-fill" v-if="this.mixin_set_btn(this.$options.name, 'btnSaveAll')" @click="allResult">일괄처리</v-btn>
                </div>
            </div>
            <div class="jh-form-wrap is-pt-10">
                <div class="jh-ui-header">
                    <h2>신청 상세정보</h2>
                    <div class="is-right">
                    </div>
                </div>
                <table class="jh-tbl-detail">
                    <colgroup>
                        <col width="90px">
                        <col>
                        <col width="90px">
                        <col>
                        <col width="90px">
                        <col>
                        <col width="90px">
                        <col>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th><label>신청업무</label></th>
                            <td>
                                <v-select class="jh-form is-trns" v-model="REGIST_APLY_WORK_CD" :items="AW01_COMBO" item-text="CD_NM" item-value="CD" disabled ></v-select>
                            </td>
                            <th><label>신청일자</label></th>
                            <td colspan="5">
                                <input type="text" class="jh-form is-trns" v-model="REGIST_APLY_DT" readonly>
                            </td>
                        </tr>
                        <tr>
                            <th><label>고객번호</label></th>
                            <td>
                                <input type="text" class="jh-form is-trns" v-model="REGIST_CUST_NO" readonly>
                            </td>
                            <th><label>고객명</label></th>
                            <td>
                                <input type="text" class="jh-form is-trns" v-model="REGIST_CUST_NM" readonly>
                            </td>
                            <th><label>사번</label></th>
                            <td>
                                <input type="text" class="jh-form is-trns" v-model="REGIST_CSLT_ID" readonly>
                            </td>
                            <th><label>신청직원명</label></th>
                            <td>
                                <input type="text" class="jh-form is-trns" v-model="CSLT_NM" readonly>
                            </td>
                        </tr>
                        <tr>
                            <th rowspan="2"><label>전화번호</label></th>
                            <td>
                                <div class="jh-cols">
                                    <input type="radio" class="jh-form" name="tel" id="tel_1" value="0" v-model="REGIST_TNO_CD" disabled><label for="tel_1">휴대전화</label>
                                    <input type="text" class="jh-form is-trns" v-model="REGIST_MAIN_TNO" readonly>
                                </div>
                            </td>
                            <th rowspan="3"><label>주소</label></th>
                            <td colspan="5">
                                <div class="jh-cols">
                                    <input type="radio" class="jh-form" name="address" id="address_1" value="0" v-model="REGIST_ADDR_CD" disabled><label for="address_1">자택주소</label>
                                    <v-select 
                                        class="jh-form is-col-fix" 
                                        style="width: 75px;" 
                                        :items="AW03_COMBO"
                                        item-text="CD_NM"
                                        item-value="CD"
                                        placeholder="선택"
                                        v-model="REGIST_OWNHOM_ROAD_NM_YN"
                                        disabled>
                                    </v-select>
                                    <!-- <span class="is-address-label is-new">도로명</span> -->
                                    <!-- <span class="is-address-label is-old">지번</span> -->
                                    <input type="text" class="jh-form is-txt-center is-col-fix" style="width: 60px;" v-model="REGIST_OWNHOM_PSNO" disabled>
                                    <input type="text" class="jh-form" v-model="REGIST_OWNHOM_ADR" readonly>
                                    <input type="text" class="jh-form is-col-fix" style="width:36.5%" v-model="REGIST_OWNHOM_DETAIL_ADR" readonly>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="jh-cols">
                                    <input type="radio" class="jh-form" name="tel" id="tel_2" value="1" v-model="REGIST_TNO_CD" disabled><label for="tel_2">직접입력</label>
                                    <input type="text" class="jh-form is-trns" v-model="REGIST_SELF_TNO" disabled>
                                </div>
                            </td>
                            <td colspan="5">
                                <div class="jh-cols">
                                    <input type="radio" class="jh-form" name="address" id="address_2" value="1" v-model="REGIST_ADDR_CD" disabled><label for="address_2">직장주소</label>
                                    <v-select 
                                        class="jh-form is-col-fix" 
                                        style="width: 75px;" 
                                        :items="AW03_COMBO"
                                        item-text="CD_NM"
                                        item-value="CD"
                                        placeholder="선택"
                                        v-model="REGIST_WRC_ROAD_NM_YN"
                                        disabled>
                                    </v-select>
                                    <!-- <span class="is-address-label is-new">도로명</span> -->
                                    <!-- <span class="is-address-label is-old">지번</span> -->
                                    <input type="text" class="jh-form is-txt-center is-col-fix" style="width: 60px;" v-model="REGIST_WRC_PSNO" disabled>
                                    <input type="text" class="jh-form" v-model="REGIST_WRC_ADR" readonly>
                                    <input type="text" class="jh-form is-col-fix" style="width:36.5%" v-model="REGIST_WRC_DETAIL_ADR" readonly>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th><label>비고</label></th>
                            <td>
                                <input type="text" class="jh-form is-trns" v-model="REGIST_RMRK" disabled>
                            </td>
                            <td colspan="5">
                                <div class="jh-cols">
                                    <input type="radio" class="jh-form" name="address" id="address_3" value="2" v-model="REGIST_ADDR_CD" disabled><label for="address_3">직접입력</label>
                                    <v-select 
                                        class="jh-form is-col-fix" 
                                        style="width: 75px;" 
                                        :items="AW03_COMBO"
                                        item-text="CD_NM"
                                        item-value="CD"
                                        placeholder="선택"
                                        v-model="REGIST_SELF_ROAD_NM_YN"
                                        disabled>
                                    </v-select>
                                    <!-- <v-select 
                                    class="jh-form is-trns is-col-fix"
                                    style="width: 60px;" 
                                    v-model="REGIST_SELF_ROAD_NM_YN"
                                    :items="AW03_COMBO"
                                    item-text="CD_NM"
                                    item-value="CD"
                                    placeholder="선택"
                                    disabled></v-select> -->
                                    <!-- <span class="is-address-label is-new">도로명</span> -->
                                    <input type="text" class="jh-form is-txt-center is-col-fix" style="width: 60px;" v-model="REGIST_SELF_PSNO" disabled>
                                    <input type="text" class="jh-form" v-model="REGIST_SELF_ADR" readonly>
                                    <input type="text" class="jh-form is-col-fix" style="width:36.5%" v-model="REGIST_SELF_DETAIL_ADR" readonly>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import api from '../../store/apiUtil.js';
import { mixin } from "@/mixin/mixin.js";
import dataTables from "@/components/DataTables";

export default {
    name: "MENU_E010502", //name은 'MENU_' + 파일명 조합
    mixins: [mixin],
    props: {
    },
    components: {
        dataTables
    },
    data() {
        return {
            headers: [
                { text: '순번', value: 'ROWNUM', align: 'center', width: '50px' },
                { text: '신청일자', value: 'APLY_DT', align: 'center', width: '100px'},
                { text: '고객번호', value: 'CUST_NO', align: 'center', width: '90px'},
                { text: '고객명', value: 'CUST_NM', align: 'center', width: '90px'},
                { text: '우편번호', value: 'PSNO', align: 'center', width: '80px', sortable: false},
                { text: '주소', value: 'ADDR' },
                { text: '연락처', value: 'TNO', align: 'center', width: '120px'},
                { text: '비고', value: 'RMRK', width: '15%', sortable: false},
                { text: '신청직원', value: 'CSLT_ID_NM', align: 'center', width: '90px'},
                { text: '처리자', value: 'PROC_MAN_NM', align: 'center', width: '90px'},
                { text: '처리결과', value: 'PROC_RSLT_NM', align: 'center', width: '90px'},
            ],
            selectedList:[],
            chekList:[],

            menuStartDate: false,
            menuEndDate: false,
            from: moment().format("YYYY-MM-DD"),
            to: moment().format("YYYY-MM-DD"),
            dates: [
                (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            ],

            AW01_COMBO: [],
            AW03_COMBO: [],
            AW04_COMBO: [],
            AW05_COMBO: [],

            dataTableOptions: {
                fixedHeader: true,
                hideDefaultFooter: true,
                height: '395px',
                items: undefined,
                itemKey: 'ROWNUM',
                itemsPerPage: 50,
                noDataText: "조회된 결과가 없습니다.",
                page: 1,
                showSelect: true,
                // singleSelect: true,
                disableSort: true,
                itemClass: 'isActiveRow',
                load: undefined,
            },
            paginationOptions: {
                totalVisible: 10
            },

            FIRST_INQRY_YN: "Y",

            // 조회조건
            INQRY_CUST_NO: '',
            INQRY_CUST_NM: '',
            INQRY_CSLT_ID: '',
            INQRY_CSLT_NM: '',
            INQRY_PROC_RSLT_CD: '',

            checkbox1: false,

            //저장조건
            REGIST_CUST_NO: '',
            REGIST_APLY_DT: '',
            REGIST_APLY_TIME: '',
            REGIST_CUST_NM: '',
            REGIST_APLY_WORK_CD: '',
            REGIST_CALL_ID: '', //콜ID
            REGIST_ADDR_CD: '', //주소코드

            REGIST_OWNHOM_ROAD_NM_YN: '', //자택도로명여부
            REGIST_OWNHOM_PSNO: '', //자택우편번호
            REGIST_OWNHOM_ADR: '', //자택주소
            REGIST_OWNHOM_DETAIL_ADR: '', //자택상세주소

            REGIST_WRC_ROAD_NM_YN: '', //직장도로명여부
            REGIST_WRC_PSNO: '', //직장우편번호
            REGIST_WRC_ADR: '', //직장주소
            REGIST_WRC_DETAIL_ADR: '', //직장상세주소

            REGIST_SELF_ROAD_NM_YN: '', //직접도로명여부
            REGIST_SELF_PSNO: '', //직접우편번호
            REGIST_SELF_ADR: '', //집접주소
            REGIST_SELF_DETAIL_ADR: '', //직접주소상세

            REGIST_TNO_CD: '', //전화번호코드
            REGIST_MAIN_TNO: '', //메인전화번호 
            REGIST_SELF_TNO: '', //직접전화번호 

            REGIST_CSLT_ID: '', //사번
            REGIST_RMRK: '', //비고 
            REGIST_PROC_NM: '', //처리자명
            REGIST_PROC_MAN: '', //처리자ID
            REGIST_PROC_RSLT_CD: '0', //처리결과코드
            PROC_RSLT_CD: '', //원본처리결과코드

            TNO_CD1:'',
            TNO_CD2:'',
            CSLT_NM: '', //신청직원명 

            selectedRow: [],
        };
    },

    created() {
        this.dataTableOptions.headers = this.headers;
        this.dataTableOptions.items = this.rowNum;
    },
    mounted(){
        this.getSelBoxList();
    },
    computed: {        
        dataTableInstance: function() {
            return this.$refs.dataTable;
        },      
        initHeaders(){
            return {
                SERVICE: 'setting.system.cmmn-CmpgnAplyWork-manage',
                METHOD: "",
                TYPE: 'BIZ_SERVICE',
            };
        },
    },
    watch: {
        "checkbox1": function (val) {
            this.selectbutton();
        },
    },

    methods: {
        fromOnInput() {
            // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
            if (/^\d{4}-\d{2}-\d{2}$/.test(this.from) && moment(this.from).isValid()) {
                // 종료일자보다 이후를 입력했으면 종료일자로 변경
                if (moment(this.from).isAfter(this.dates[1])) {
                    this.$nextTick(() => {
                        this.from = this.dates[1];
                    });
                }

                this.$nextTick(() => {
                    this.dates[0] = this.from;
                    // datepick 다시 랜더링
                    this.menuStartDate = false;
                    this.menuStartDate = true;
                });
            }
        },
        changeDate(type) {
            if(type == 'start') {
                if (moment(this.dates[0]).isBefore(moment(this.dates[1]).subtract(90, 'days').format('YYYY-MM-DD'))) {
                    this.common_alert('검색 기간은 최대 3개월까지 선택 가능합니다.');
                    const date = moment().format("YYYY-MM-DD");
                    this.dates[0] = date;
                    this.from = date;
                    return;
                } else {
                    this.from = this.dates[0];
                }
            } else if (type == 'end') {
                if (moment(this.dates[1]).isAfter(moment(this.dates[0]).add(90, 'days').format('YYYY-MM-DD'))) {
                    this.common_alert('검색 기간은 최대 3개월까지 선택 가능합니다.');
                    const date = moment().format("YYYY-MM-DD");
                    this.dates[1] = date;
                    this.to = date;
                    return;
                } else {
                    this.to = this.dates[1];
                }
            }
        },
        startDate(e) {
            this.from = e;
        },
        toOnInput() {
            // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
            if (/^\d{4}-\d{2}-\d{2}$/.test(this.to) && moment(this.to).isValid()) {
                // 시작일자보다 이전을 입력했으면 시작일자로 변경
                if (moment(this.to).isBefore(this.dates[0])) {
                    this.$nextTick(() => {
                        this.to = this.dates[0];
                    });
                }

                this.$nextTick(() => {
                    this.dates[1] = this.to;
                    // datepick 다시 랜더링
                    this.menuEndDate = false;
                    this.menuEndDate = true;
                });
            }
        },
        endDate(e) {
            this.to = e;
        },
        onClickRow(item, row) {

            // row.select(true);
            // row.isSelected = true;
            this.selectedRow.push(row);

            this.REGIST_CUST_NO = item.CUST_NO;
            this.REGIST_APLY_DT = item.APLY_DT;
            this.REGIST_APLY_TIME = item.APLY_TIME;
            this.REGIST_CUST_NM = item.CUST_NM;
            this.REGIST_APLY_WORK_CD = item.APLY_WORK_CD;
            this.REGIST_CALL_ID = item.CALL_ID; //콜ID
            this.REGIST_ADDR_CD = item.ADDR_CD; //주소코드

            this.REGIST_OWNHOM_ROAD_NM_YN = item.OWNHOM_ROAD_NM_YN; //자택도로명여부
            this.REGIST_OWNHOM_PSNO = item.OWNHOM_PSNO; //자택우편번호
            this.REGIST_OWNHOM_ADR = item.OWNHOM_ADR; //자택주소
            this.REGIST_OWNHOM_DETAIL_ADR = item.OWNHOM_DETAIL_ADR; //자택상세주소

            this.REGIST_WRC_ROAD_NM_YN = item.WRC_ROAD_NM_YN; //직장도로명여부
            this.REGIST_WRC_PSNO = item.WRC_PSNO; //직장우편번호
            this.REGIST_WRC_ADR = item.WRC_ADR; //직장주소
            this.REGIST_WRC_DETAIL_ADR = item.WRC_DETAIL_ADR; //직장상세주소

            this.REGIST_SELF_ROAD_NM_YN = item.SELF_ROAD_NM_YN; //직접도로명여부
            this.REGIST_SELF_PSNO = item.SELF_PSNO; //직접우편번호
            this.REGIST_SELF_ADR = item.SELF_ADR; //집접주소
            this.REGIST_SELF_DETAIL_ADR = item.SELF_DETAIL_ADR; //직접주소상세

            this.REGIST_TNO_CD = item.TNO_CD; //전화번호코드
            this.REGIST_MAIN_TNO = item.MAIN_TNO.indexOf('-') < 0 ? this.mixin_telNumFormat(item.MAIN_TNO) : item.MAIN_TNO; //메인전화번호 
            this.REGIST_SELF_TNO = item.SELF_TNO.indexOf('-') < 0 ? this.mixin_telNumFormat(item.SELF_TNO) : item.SELF_TNO; //직접전화번호 

            this.REGIST_CSLT_ID = item.CSLT_ID; //사번
            this.REGIST_RMRK = item.RMRK; //비고 
            this.REGIST_PROC_MAN = item.PROC_MAN; //처리자ID
            this.REGIST_PROC_NM = item.PROC_NM; //처리자이름
            this.REGIST_PROC_RSLT_CD = item.PROC_RSLT_CD; //처리결과코드
            this.PROC_RSLT_CD = item.PROC_RSLT_CD; //처리결과코드

            this.CSLT_NM = item.USER_NM
        },
        initialization(){
            this.REGIST_CUST_NO = '';
            this.REGIST_APLY_DT = '';
            this.REGIST_APLY_TIME = '';
            this.REGIST_CUST_NM = '';
            this.REGIST_APLY_WORK_CD = '';
            this.REGIST_CALL_ID = '';
            this.REGIST_ADDR_CD = '';

            this.REGIST_OWNHOM_ROAD_NM_YN = '';
            this.REGIST_OWNHOM_PSNO = '';
            this.REGIST_OWNHOM_ADR = '';
            this.REGIST_OWNHOM_DETAIL_ADR = '';

            this.REGIST_WRC_ROAD_NM_YN = '';
            this.REGIST_WRC_PSNO = '';
            this.REGIST_WRC_ADR = '';
            this.REGIST_WRC_DETAIL_ADR = '';

            this.REGIST_SELF_ROAD_NM_YN = '';
            this.REGIST_SELF_PSNO = '';
            this.REGIST_SELF_ADR = '';
            this.REGIST_SELF_DETAIL_ADR = '';

            this.REGIST_TNO_CD = '';
            this.REGIST_MAIN_TNO = '';
            this.REGIST_SELF_TNO = '';

            this.REGIST_CSLT_ID = '';
            this.REGIST_RMRK = '';
            this.REGIST_PROC_MAN = '';
            this.REGIST_PROC_NM = '';
            this.REGIST_PROC_RSLT_CD = '';
            this.PROC_RSLT_CD = '';

            this.CSLT_NM = '';
        },

        selectbutton() {

            this.dataTableOptions.load = this.selectAplyWork;

            if (this.dataTableOptions.page > 1)this.dataTableOptions.page = 1;
            else this.dataTableInstance.loadData();
            
        },

        async selectAplyWork(loadOptions){

            const table = this.$el.children[2].children[1].children[0].children[0];
            table.scrollTop = 0;

            // if (this.selectedRow.length > 0) {
            //     this.selectedRow.forEach(item => {
            //         item.select(false);
            //     })
            // }

            if (this.FIRST_INQRY_YN == "Y"){
                return this.FIRST_INQRY_YN = "N"
            }

            if (!this.selectValidate()) {
                return;
            }

            let requestData = {
                headers: {
                    SERVICE: this.initHeaders.SERVICE,
                    METHOD: this.initHeaders.METHOD,
                    TYPE: this.initHeaders.TYPE,
                },
                sendData: {}
            };
            requestData.headers["URL"] = "/api/phone/cmpgn/aply-work/list";
            requestData.headers["METHOD"] = "select";
            requestData.headers["ASYNC"] = false;
            requestData.headers["ROW_CNT"] = loadOptions.itemsPerPage;
            requestData.headers["PAGES_CNT"] = loadOptions.page; 

            requestData.sendData["STR_APLY_DT"] = this.dates[0].replace(/\-/g, "");
            requestData.sendData["END_APLY_DT"] = this.dates[1].replace(/\-/g, "");
            requestData.sendData["CUST_NO"] = this.INQRY_CUST_NO;
            requestData.sendData["CUST_NM"] = this.INQRY_CUST_NM;
            requestData.sendData["CSLT_ID"] = this.INQRY_CSLT_ID;
            requestData.sendData["CSLT_NM"] = this.INQRY_CSLT_NM;
            
            if (this.checkbox1){
                requestData.sendData["PROC_RSLT_CD"] = '0';
            }

            const response = await this.common_postCall(requestData);
            this.initialization();

            if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {
                let header = response.HEADER;
                let data = response.DATA;

                data.forEach(item => {
                    item.TNO = item.TNO.indexOf('-') < 0 ? this.mixin_telNumFormat(item.TNO) : item.TNO;
                });

                return {
                    data: data,
                    totalCount: header.TOT_COUNT
                };
            }
        },

        async getSelBoxList() {
            let requestData = {
                headers: {
                    SERVICE: this.initHeaders.SERVICE,
                    METHOD: this.initHeaders.METHOD,
                    TYPE: this.initHeaders.TYPE,
                },
                sendData: {}
            };

            requestData.headers["URL"] = "/api/code/common/code-book/selectCode";
            requestData.headers["METHOD"] = "selectCode";
            requestData.headers["ASYNC"] = false;

            let sendList = [];
            sendList.push("AW01"); //신청업무코드
            sendList.push("AW02"); //주소코드
            sendList.push("AW03"); //도로명여부
            sendList.push("AW04"); //전화번호코드
            sendList.push("AW05"); //처리결과코드

            requestData.sendData["GROUP_CD"] = sendList

            const response = await this.common_postCall(requestData);

            console.log(response)

            if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {

                for (var i = 0; i < response.DATA.length ; i++){

                    if (response.DATA[i].GROUP_CD == "AW01"){
                        this.AW01_COMBO.push(response.DATA[i]);
                    }else if (response.DATA[i].GROUP_CD == "AW03"){
                        this.AW03_COMBO.push(response.DATA[i]);
                    }else if (response.DATA[i].GROUP_CD == "AW04"){
                        this.AW04_COMBO.push(response.DATA[i]);
                    }else if (response.DATA[i].GROUP_CD == "AW05"){
                        this.AW05_COMBO.push(response.DATA[i]);
                    }
                }
            }
        },
        
        excelDownload(table_id, file_name) {
            //number,date,time,string - n,d,t,s
            var exceltypeArr = {};
            exceltypeArr[0] = "n";           
            exceltypeArr[1] = "s";  
            exceltypeArr[2] = "s";  
            exceltypeArr[3] = "s";       
            exceltypeArr[4] = "s";     
            exceltypeArr[5] = "s";     
            exceltypeArr[6] = "s"; 
            exceltypeArr[7] = "s"; 
            exceltypeArr[8] = "s"; 
            exceltypeArr[9] = "s"; 
            exceltypeArr[10] = "s";


            this.table_id = table_id
            this.file_name = file_name
            this.mixin_common_exportExcel(exceltypeArr)
        },

        async updateResult() { //처리

            if (!this.updateValidate()) {
                return;
            }

            this.common_confirm("처리하시겠습니까?", async () => {
                let requestData = {
                    headers: {
                        SERVICE: this.initHeaders.SERVICE,
                        METHOD: this.initHeaders.METHOD,
                        TYPE: this.initHeaders.TYPE,
                    },
                    sendData: {}
                };
    
                requestData.headers["URL"] = "/api/phone/cmpgn/aply-work/result";
                requestData.headers["METHOD"] = "updateResult";
                requestData.headers["ASYNC"] = false;
    
                requestData.sendData["CUST_NO"] = this.REGIST_CUST_NO;
                requestData.sendData["APLY_DT"] = this.REGIST_APLY_DT.replaceAll('-', '');
                requestData.sendData["APLY_TIME"] = this.REGIST_APLY_TIME;
                requestData.sendData["PROC_MAN"] = this.$store.getters['userStore/GE_USER_ROLE'].userId;
                requestData.sendData["PROC_RSLT_CD"] = this.REGIST_PROC_RSLT_CD;
                requestData.sendData["CHNG_MAN"] = this.$store.getters['userStore/GE_USER_ROLE'].userId;
                requestData.sendData["UPD_ID"] = this.$store.getters['userStore/GE_USER_ROLE'].userId;
    
                const response = await this.common_postCall(requestData);
    
                if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {
                    this.common_alert(`정상적으로 처리되었습니다.`, 'done')
                    this.dataTableInstance.loadData();
                }
            });
        },
        async allResult() { //일괄처리

            if (!this.allValidate()) {
                return;
            }

            this.common_confirm("일괄처리하시겠습니까?", async () => {
                let sendList = this.chekList;
    
                let requestData = {
                    headers: {
                        SERVICE: this.initHeaders.SERVICE,
                        METHOD: this.initHeaders.METHOD,
                        TYPE: this.initHeaders.TYPE,
                    },
                    sendData: {sendList}
                };
                
                requestData.headers["URL"] = "/api/phone/cmpgn/aply-work/result";
                requestData.headers["METHOD"] = "allResult";
                requestData.headers["ASYNC"] = false;
    
                const response = await this.common_postCall(requestData);
    
                if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {
                    this.common_alert(`정상적으로 처리되었습니다.`, 'done')
                    this.dataTableInstance.loadData();
                }
            });

        },
        selectValidate() {
            let val = true;
            if (this.mixin_isEmpty(this.dates[0])){
                this.common_alert(`조회시작일자가 없습니다`,'error')
                val = false;
            }else if (this.mixin_isEmpty(this.dates[1])){
                this.common_alert(`조회종료일자가 없습니다`,'error')
                val = false;
            }

            return val;
        },
        updateValidate(item) {
            let val = true;
            if (this.mixin_isEmpty(this.REGIST_CUST_NO)){
                this.common_alert(`신청 상세정보에 값이 없습니다`,'error')
                val = false;
            }else if (this.mixin_isEmpty(this.REGIST_PROC_RSLT_CD)){
                this.common_alert(`처리결과가 없습니다<br/>다시 확인하고 저장해 주세요.`,'error')
                val = false;
            }else if (this.PROC_RSLT_CD == '1'){
                this.common_alert(`완료건은 처리 할수 없습니다`,'error')
                this.REGIST_PROC_RSLT_CD = '1'
                val = false;
            }

            return val;
        },
        allValidate() {
            let val = true;
            if( this.mixin_isEmpty(this.selectedList) || this.selectedList.length < 1){
                this.common_alert(`체크된 항목이 존재하지 않습니다.`, 'error');
                val = false;
            }else if (this.mixin_isEmpty(this.REGIST_PROC_RSLT_CD)){
                this.common_alert(`처리결과가 없습니다<br/>다시 확인하고 저장해 주세요.`,'error')
                val = false;
            }else {
                this.chekList = [];

                for(let i in this.selectedList){

                    if (this.selectedList[i].PROC_RSLT_CD == '1'){
                        this.common_alert(`완료된건은 처리할수 없습니다.`, 'error');
                        val = false;
                        break;
                    }
                    let obj = {
                        CUST_NO : this.selectedList[i].CUST_NO,
                        APLY_DT : this.selectedList[i].APLY_DT.replaceAll('-', ''),
                        APLY_TIME : this.selectedList[i].APLY_TIME,
                        PROC_RSLT_CD : this.REGIST_PROC_RSLT_CD,
                        PROC_MAN: this.$store.getters['userStore/GE_USER_ROLE'].userId,
                        CHNG_MAN: this.$store.getters['userStore/GE_USER_ROLE'].userId,
                        UPD_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
                    };
                    this.chekList.push(obj);
                }
            }
            
            return val;
        },
    },
};
</script>

<style></style>